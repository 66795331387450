<template>
  <div class="headmeun">
    <div class="meunhead">
      <img class="logo" src="https://oss.001ke.com/website/logo/logo.png" alt="logo" />
      <p @click="close">关闭</p>
    </div>

    <van-cell>
      <p class="common">
        <router-link :to="{ name: 'BaseLayout' }">首页</router-link>
      </p>
    </van-cell>

    <van-cell>
      <!-- <p class="common">产品矩阵</p> -->
      <van-collapse v-model="activeNames" :border="false">
        <van-collapse-item
          title="产品矩阵"
          name="1"
          class="common"
          :border="false"
        >
          <div class="productchildmeun">
            <div class="item">
              <p>小程序玩法</p>
              <div class="meunchid">
                <p class="themeum">
                  <router-link :to="{ name: 'platform' }">知识付费</router-link>
                </p>
                <p class="themeum">
                  <router-link :to="{ name: 'platformdy' }">抖音端</router-link>
                </p>
                <p class="themeum">
                  <router-link :to="{ name: 'platformks' }">快手端</router-link>
                </p>
                <p class="themeum">
                  <router-link :to="{ name: 'platformwx' }">微信端</router-link>
                </p>
              </div>
            </div>

            <div class="item">
              <p>达人端后台</p>
              <div class="meunchid">
                <p class="themeum">
                  <router-link :to="{ name: 'platformbkserve' }"
                    >智能服务平台</router-link
                  >
                </p>
                <p class="themeum">
                  <router-link :to="'/platformbkserve#part1'"
                    >付费用户数据统计</router-link
                  >
                </p>
                <p class="themeum">评论系统</p>
                <p class="themeum">分销市场</p>
                <p class="themeum">
                  <router-link :to="'/platformbkserve#part4'"
                    >订单管理</router-link
                  >
                </p>
              </div>
            </div>

            <div class="item">
              <p>更多业务</p>
              <div class="meunchid">
                <p class="themeum">
                  <router-link :to="{ name: 'platformkgstore' }"
                    >知识店铺解决方案</router-link
                  >
                </p>
                <p class="themeum">MCN合作</p>
                <p class="themeum">讲师直播客户端</p>
                <p class="themeum">易知驾考助手app</p>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </van-cell>

    <van-cell>
      <p class="common">
        <router-link :to="{ name: 'platformhelp' }">达人扶持计划</router-link>
      </p>
    </van-cell>

    <van-cell>
      <p class="common">
        <router-link :to="{ name: 'personcase' }">客户案例</router-link>
      </p>
    </van-cell>

    <!-- <van-cell>
      <p class="common">客户案例</p>
    </van-cell> -->

    <van-cell @click="toHelpDocs">
      <p class="common">帮助中心</p>
    </van-cell>

    <div class="footloginbox">
      <van-button
        plain
        type="primary"
        size="small"
        color="#ff8f29"
        style="width: 40%"
        @click="toUser"
        >学员登录</van-button
      >
      <van-button
        plain
        type="info"
        size="small"
        class="author"
        @click="toAuthor"
        >讲师登录</van-button
      >
    </div>
  </div>
</template>

<script>
import { Cell, Collapse, CollapseItem, Button } from "vant";
export default {
  components: {
    [Cell.name]: Cell,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Button.name]: Button,
  },
  data() {
    return {
      activeNames: ["0"],
    };
  },
  methods: {
    close() {
      this.$parent.$parent.show = false;
      console.log(this.$parent.$parent);
    },
    toUser() {
      const url = "https://user.001ke.com/";
      window.open(url);
    },
    toAuthor() {
      const url = "https://author.001ke.com/";
      window.open(url);
    },
    toHelpDocs() {
      const url = "https://www.001ke.com/yedengdocs/";
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.headmeun {
  width: 75vw;
  min-height: 100vh;
  padding: 15px;
  background: #fff;
  .meunhead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .logo {
      width: calc(100vw * 0.21);
      object-fit: contain;
    }
  }
}

.common {
  font-size: 16px;
  font-weight: 600;
  color: rgb(73 80 87);
  padding-left: 5px;
}

.productchildmeun {
  transition: all 0.25s linear;
  position: relative;
  top: 10px;
  padding-bottom: 10px;
  .item {
    font-size: 12px;
    margin-bottom: 20px;
    .title {
    }
    .meunchid {
      display: flex;
      flex-wrap: wrap;
      .themeum {
        margin-top: 10px;
        width: 50%;
        color: #000;
      }
    }
  }
}

.van-cell {
  padding: 15px 0;
  /deep/ .van-cell--clickable {
    padding: 0 !important;

    color: rgb(73 80 87) !important;

    font-size: 16px;
    .van-collapse-item__title {
      padding: 0 !important;

      color: rgb(73 80 87) !important;
    }
  }
}

.van-cell::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.266667vw;
  bottom: 0;
  left: 0.266667vw;
  border-bottom: 1px solid #ebedf0;
  transform: scaleY(0.5);
  width: 100%;
}

.footloginbox {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 8vw);
  display: flex;
  justify-content: space-evenly;
}
.author {
  background: linear-gradient(180deg, #fb4e3e 0%, #ff8f29 100%);
  color: #fff;
  border: none;
  margin-left: 20px;
  width: 40%;
}
</style>
